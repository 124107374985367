import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Youtube from 'react-youtube'
import LocaleContext from '../../context/LocaleContext'
import PageContext from '../../context/PageContext'
import { Locale, localisePath } from '../../domain/locale'
import { INDEX_PATH, PRODUCTS_PATH } from '../../domain/path'
import Layout from '../../components/layout/Layout'
import Seo from '../../components/layout/Seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint, faEyeDropper, faFish } from '@fortawesome/free-solid-svg-icons'
import {
    BeakerIcon,
    ChevronRightIcon,
    PlusIcon,
    MenuAlt4Icon,
    HeartIcon,
} from '@heroicons/react/solid'
import BackToNatureIcon from '../../assets/back-to-nature.svg'
import CassavaIcon from '../../assets/cassava.svg'
import MarineFriendlyIcon from '../../assets/marine-friendly.svg'
import NoMicroplasticIcon from '../../assets/no-microplastic.svg'
import NonToxicIcon from '../../assets/non-toxic.svg'
import TelobagFavicon from '../../assets/favicon.svg'

const localisedProductsPath = localisePath(PRODUCTS_PATH, Locale.AU)

function IndexPage() {
    const { setLocale } = useContext(LocaleContext)

    useEffect(() => {
        setLocale(Locale.AU)
    }, [])

    return (
        <PageContext.Provider
            value={{
                basepath: INDEX_PATH,
                locale: Locale.AU,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Home"
                    titleCompany="Telobag AU"
                    description="We make biodegradable and compostable bags which don't cost our home"
                    relativeUrl={localisePath(INDEX_PATH, Locale.AU)}
                />
                {/* Hero section */}
                <section className="bg-green-500 text-gray-600 body-font -mt-6 md:-mt-12 lg:-mt-9">
                    <div className="container max-w-7xl mx-auto flex px-5 md:px-24 py-20 md:py-24 items-center justify-center flex-col md:flex-row">
                        <div className="lg:flex-grow md:w-3/4 md:pr-16 lg:pr-24 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                            <h1 className="title-font lg:text-5xl text-3xl mb-4 font-bold text-gray-100">
                                Zero Waste Packaging
                            </h1>
                            <p className="mb-8 leading-relaxed text-md lg:text-xl text-gray-200">
                                No microplastics, no residue, no waste
                            </p>
                            <div className="flex justify-center w-3/4 md:justify-start">
                                <div className="rounded-md shadow xl:w-96">
                                    <Link
                                        to={localisedProductsPath}
                                        className="w-full inline-flex items-center justify-center px-6 py-3 xl:w-96 xl:mt-2 xl:px-12 xl:py-5 border border-transparent shadow-sm text-md lg:text-lg xl:text-xl text-white font-bold rounded-lg xl:rounded-xl bg-indigo-500 hover:bg-indigo-600"
                                    >
                                        Shop Now
                                        {/*<!-- Heroicon name: ChevronRight -->*/}
                                        <ChevronRightIcon className="ml-1 -mr-1 h-6 w-6" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="lg:max-w-lg lg:w-full md:w-1/4 w-5/6">
                            <StaticImage
                                src="../../assets/landing-telocarrier.png"
                                alt="Image of Telo Carrier with transparent background"
                                className="object-cover object-center rounded"
                                placeholder="blurred"
                            />
                        </div>
                    </div>
                </section>

                {/* Features section */}
                <section className="mx-6 py-12 bg-white">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="mb-10 lg:mb-14 lg:text-center">
                            <h2 className="text-base text-green-500 font-semibold tracking-wide uppercase">
                                What is Telobag ?
                            </h2>
                            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                Bags that don&#39;t cost our home
                            </p>
                            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                Telobag is made of ingredients which are
                                individually harmless to the environment, and
                                together, they form a beautiful sustainable
                                packaging.
                            </p>
                        </div>

                        <div className="flex flex-col items-center">
                            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                                <div className="flex md:mx-2">
                                    <div className="flex-shrink-0">
                                        <CassavaIcon className="object-contain h-16 w-16" />
                                    </div>
                                    <div className="ml-4">
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            Cassava Roots
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            Cassava is a root vegetable rich in
                                            starch and a staple ingredient in
                                            Southeast Asian countries. It is
                                            similar to yam and sweet potato.
                                        </dd>
                                    </div>
                                </div>

                                <div className="flex md:mx-2">
                                    <div className="flex-shrink-0">
                                        {/* Heroicons: Beaker */}
                                        <BeakerIcon className="text-gray-700 h-16 w-16" />
                                    </div>
                                    <div className="ml-4">
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            Glycerin
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            Colourless, odourless liquid which
                                            is prevalent in skincare products
                                            such as soaps, moisturisers and
                                            sometimes, cough syrups.
                                        </dd>
                                    </div>
                                </div>

                                <div className="flex md:mx-2">
                                    <div className="flex-shrink-0">
                                        {/* Heroicons: Beaker */}
                                        <BeakerIcon className="text-gray-700 h-16 w-16" />
                                    </div>
                                    <div className="ml-4">
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            Polyvinyl Alcohol (PVOH)
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            A harmless and water-soluble
                                            synthetic polymer, present in small
                                            amounts. One of its uses currently
                                            is pharmaceutical industry to bind
                                            substances.
                                        </dd>
                                    </div>
                                </div>

                                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10">
                                    <div className="flex md:mx-2">
                                        <div className="flex-shrink-0">
                                            <FontAwesomeIcon
                                                icon={faTint}
                                                color="#1ea0db"
                                                size="3x"
                                                fixedWidth
                                            />
                                        </div>
                                        <div className="ml-4">
                                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                                Environmental-friendly Ink
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Our inks make up less than 1% of
                                                the total mass. Our inks are
                                                mostly organic, except for black
                                                and white, which contain
                                                minuscule traces of anorganics.
                                            </dd>
                                        </div>
                                    </div>
                                </dl>

                                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10">
                                    <div className="flex md:mx-2">
                                        <div className="flex-shrink-0">
                                            {/* <DropperIcon className="text-curious-blue-500 h-16 w-16"/> */}
                                            <FontAwesomeIcon
                                                icon={faEyeDropper}
                                                size="3x"
                                                color="#4b5563"
                                                fixedWidth
                                            />
                                        </div>
                                        <div className="ml-4">
                                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                                Food colouring
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Food-safe colouring is used for
                                                the base colour of our bags,
                                                making it safe for the
                                                environment too.
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </dl>
                        </div>
                    </div>
                </section>

                <section className="mx-6 py-8 lg:py-16 overflow-hidden">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="mb-12 lg:mb-16 lg:text-center relative">
                            <h2 className="text-base text-green-500 font-semibold tracking-wide uppercase">
                                See it in action
                            </h2>
                            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                Seeing is believing
                            </p>
                            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                At the end of its lifecycle, Telobag can be
                                broken down and decomposed by both micro and
                                macro organisms.
                            </p>
                        </div>

                        <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                            <div className="relative">
                                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                    Biodegradable and Compostable
                                </h3>
                                <p className="mt-3 text-lg text-gray-500">
                                    If Telobag is used only for organic stuffs
                                    such as food, organisms digest not only
                                    Telobag, but also the food waste inside,
                                    resulting in literal zero waste.
                                </p>

                                <dl className="mt-10 space-y-10 mr-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center text-white">
                                                <BackToNatureIcon className="h-16 w-16" />
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                                Decomposes in 180 days
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Using the {` `}
                                                <a
                                                    href="https://www.jica.go.jp/english/our_work/thematic_issues/management/study_takakura.html"
                                                    className="text-indigo-500"
                                                >
                                                    Takakura
                                                </a>
                                                {` `}composting method, Telobag
                                                has been shown to disintegrate
                                                in 180 days.
                                            </dd>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center text-white">
                                                <NonToxicIcon className="h-16 w-16" />
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                                Releases food instead of poison
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                When Telobag breaks down, it
                                                will split into little pieces of
                                                starch which are safe for plants
                                                and animals to consume.
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>

                            <div
                                className="mt-10 relative lg:mt-0"
                                aria-hidden="true"
                            >
                                <Youtube
                                    videoId="uvN4J0UUyAs"
                                    className="w-full rounded-md"
                                />
                            </div>
                        </div>

                        <div className="relative mt-12 sm:mt-16 lg:mt-24">
                            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                                <div className="lg:col-start-2">
                                    <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                        Designed with marine animals in mind
                                    </h3>
                                    <p className="mt-3 text-lg text-gray-500">
                                        All of Telobag&#39;s ingredients are
                                        water-soluble and do not harm the
                                        environment.
                                    </p>

                                    <dl className="mt-10 space-y-10">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <div className="flex items-center justify-center text-white">
                                                    <MarineFriendlyIcon className="h-16 w-16" />
                                                </div>
                                            </div>
                                            <div className="ml-4">
                                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                                    Marine-friendly
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">
                                                    Telobag is hydro-philic,
                                                    which means it disintegrates
                                                    in water. Hence, it will
                                                    disappear before making its
                                                    way into the marine animals.
                                                    Even then, Telobag is safe
                                                    for them to consume.
                                                </dd>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <div className="flex items-center justify-center text-white">
                                                    <NoMicroplasticIcon className="h-16 w-16" />
                                                </div>
                                            </div>
                                            <div className="ml-4">
                                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                                    0% Microplastics
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">
                                                    Telobag is 100%
                                                    plastic-free, and thus does
                                                    not release microplastics
                                                    into the ocean.
                                                </dd>
                                            </div>
                                        </div>
                                    </dl>
                                </div>
                                <div className="mt-10 relative lg:mt-0 lg:col-start-1">
                                    <Youtube
                                        videoId="sclhnR9GNsA"
                                        className="w-full rounded-md"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mx-6 py-8 lg:py-16 overflow-hidden">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="mb-12 lg:mb-16 lg:text-center relative">
                            <h2 className="text-base text-green-500 font-semibold tracking-wide uppercase">
                                What is hydro-philic ?
                            </h2>
                            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                Telobag dissolves in water
                            </p>
                            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                This means that Telobag requires a little more
                                thoughtfulness to use.
                            </p>
                        </div>
                        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                            <p>
                                In the video where animals were consuming
                                Telobag, we mentioned the term {` `}
                                <span>hydro-philic</span>. Hydro-phillic means
                                that Telobag disintegrates and dissolves when
                                exposed to a large body of water.
                            </p>
                            <blockquote>
                                <div className="flex flex-row my-8 lg:my-14 items-center w-full mx-auto justify-center">
                                    {/* Fish icon */}
                                    <FontAwesomeIcon
                                        icon={faFish}
                                        size="3x"
                                        color="#3b82f6"
                                        className="mx-1"
                                    />
                                    {/** Heroicons: Plus */}
                                    <PlusIcon className="w-8 h-8 mx-1" />

                                    {/** Teobag icon */}
                                    <TelobagFavicon className="w-14 h-14 mx-1" />

                                    {/** Heroicons: MenuAlt4 */}
                                    <MenuAlt4Icon className="w-8 h-8 mx-1" />

                                    {/** Heroicons: Heart */}
                                    <HeartIcon className="w-14 h-14 mx-1 text-pink-500 fill-current" />
                                </div>
                            </blockquote>
                            <p>
                                This is one of Telobag&#39;s strengths in
                                combating the marine waste issue, but this also
                                forces us to be more careful in handling
                                Telobag. Hence, if you are disposing food waste,
                                try to dry it before placing it inside the bag.
                            </p>
                        </div>
                    </div>
                </section>
            </Layout>
        </PageContext.Provider>
    )
}

export default IndexPage
